import React from "react"
import styles from './banner.module.css'
import BackgroundSection from '../BackgroundSection/backgroundSection'
import Button from '../Button/button'
import logo_white from '../../images/logos/logo_white.svg'
import frisco from '../../images/logos/frisco.svg'
import eurocash from '../../images/logos/eurocash.svg'
import santander from '../../images/logos/santander.svg'
import tmobile from '../../images/logos/t-mobile.svg'
import datawise from '../../images/logos/datawise.svg'
import autocomplete_main from '../../images/gifs/pp_glowny.gif'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";

const isDesktop = typeof window !== `undefined` && window.innerWidth > 768;


class Banner extends React.Component {
  render() {
    const { intl } = this.props
    return (
      <BackgroundSection color="#001CB4" textColor="#fff" filename="banner-bg.png" isFullPage>
        <div className="row">
          <div className={styles.bannerContent + ' col-6'}>
            {isDesktop ? <img src={logo_white} alt="Locit logo light" /> : ``}
            <h1 className={styles.heading}>{intl.formatMessage({ id: 'home.banner.heading' })}</h1>
            <p className={styles.desc}>{intl.formatMessage({ id: 'home.banner.desc' })}</p>
            <Button text={intl.formatMessage({ id: 'home.banner.button' })} link={intl.formatMessage({ id: 'home.banner.button.link' })} type="white" target="blank" className={styles.button} ></Button>
          </div>
          {isDesktop ? (
            <div className={styles.imgContainer + " col-6"}><img src={autocomplete_main} alt={intl.formatMessage({ id: 'home.adresy.title' })} /></div>
          ) : ``}
        </div>
        <div className="row">
          <div className="col-8">
            <div className={styles.clientsTitle}>{intl.formatMessage({ id: 'home.banner.clients' })}</div>
            <div className="clients-logos">
              <div className={styles.logosLeft}>
                <img alt="frisco" src={frisco} />
                <img alt="eurocash" src={eurocash} />
                <img alt="santander" src={santander} />
                <img alt="t-mobile" src={tmobile} />
              </div>
            </div>
          </div>
          <div className="col-4">
            {isDesktop ? (
              <div className={styles.logosRight + ' right'}>
                <span>Powered by:</span>
                <img src={datawise} alt="DataWise logo" />
              </div>
            ) : ``}
          </div>
        </div>
      </BackgroundSection >
    )
  }
}

Banner.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  injectIntl
)(Banner)
