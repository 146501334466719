import React from "react"
import Layout from "../components/Layout/layout"
import SEO from "../components/Seo/seo"
import Banner from "../components/Banner/banner"
import ReactFullpage from '@fullpage/react-fullpage';
import Features from "../components/Features/features";
import FeatureSection from "../components/FeatureSection/featureSection";
import Footer from "../components/Footer/footer";
import { TypographyStyle, GoogleFont } from 'react-typography'
import typography from '../utils/typography'
import FullPageNav from "../components/FullPageNav/fullPageNav";
import autouzupelnianie from '../images/gifs/pp_autouzupelnianie.gif'
import standaryzacja from '../images/gifs/pp_standaryzacja.gif'
import geokodowanie from '../images/gifs/pp_geokodowanie.gif'
import obliczenia from '../images/gifs/pp_obliczenia.gif'
import potencjal from '../images/gifs/pp_dane_o.gif'
import { injectIntl, intlShape } from "react-intl";
import { compose } from "recompose";
import withPageContext from "../pageContext";

const features = (intl) => {
  return [
    {
      text: intl.formatMessage({ id: 'features.devs' }),
      icon: "devs"
    },
    {
      text: intl.formatMessage({ id: 'features.crm' }),
      icon: "bi_crm"
    },
    {
      text: intl.formatMessage({ id: 'features.analytics' }),
      icon: "analytics"
    },
    {
      text: intl.formatMessage({ id: 'features.ecommerce' }),
      icon: "ecommerce"
    }
  ]
}

class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      isFirst: true,
      isLast: false,
      index: 1
    };
  }

  componentWillUnmount() {
    document.body.children.fullPageNav.remove()
  }

  getSlidePosition(slide) {
    this.setState({
      index: slide.index,
      isFirst: slide.isFirst,
      isLast: slide.isLast
    })
  }

  render() {
    const { uri, intl } = this.props;
    const { isFirst, isLast, index } = this.state;
    return (
      <Layout location={uri} isFirst={isFirst}>
        <SEO title={intl.formatMessage({ id: 'seo.title' })} />
        <TypographyStyle typography={typography} />
        <GoogleFont typography={typography} />
        <ReactFullpage
          navigation="true"
          fixedElements={['#fullPageNav']}
          licenseKey="7FB503B7-AA154F20-A5BE7619-2C017CE4"
          onLeave={(origin, destination) => {
            this.getSlidePosition(destination)
          }}
          render={({ state, fullpageApi }) => {
            return (
              <>
                <FullPageNav up={() => fullpageApi.moveSectionUp()} down={() => fullpageApi.moveSectionDown()} type={isFirst ? 'light' : 'dark'} index={index} isFirst={isFirst} isLast={isLast} />
                <ReactFullpage.Wrapper>
                  <Banner />
                  <Features items={features(intl)} />
                  <FeatureSection
                    title={intl.formatMessage({ id: 'home.adresy.title' })}
                    text={intl.formatMessage({ id: 'home.adresy.desc' })}
                    buttonText={intl.formatMessage({ id: 'button.more' })}
                    img={autouzupelnianie}
                    gif={true}
                    link={intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.autouzupelnianie.slug' })}
                    imageRight={true}
                    background="01_yellow.png"
                  />
                  <FeatureSection
                    title={intl.formatMessage({ id: 'home.standaryzacja.title' })}
                    text={intl.formatMessage({ id: 'home.standaryzacja.desc' })}
                    buttonText={intl.formatMessage({ id: 'button.more' })}
                    img={standaryzacja}
                    gif={true}
                    link={intl.formatMessage({ id: 'nav.produkty.adresy.slug' }) + "/#" + intl.formatMessage({ id: 'nav.produkty.adresy.standaryzacja.slug' })}
                    imageRight={false}
                    background="02_yellow.png"
                  />
                  <FeatureSection
                    title={intl.formatMessage({ id: 'home.geokodowanie.title' })}
                    text={intl.formatMessage({ id: 'home.geokodowanie.desc' })}
                    buttonText={intl.formatMessage({ id: 'button.more' })}
                    img={geokodowanie}
                    gif={true}
                    link={intl.formatMessage({ id: 'nav.produkty.geokodowanie.slug' })}
                    imageRight={true}
                    background="03_yellow.png"
                  />
                  <FeatureSection
                    title={intl.formatMessage({ id: 'home.obliczenia.title' })}
                    text={intl.formatMessage({ id: 'home.obliczenia.desc' })}
                    buttonText={intl.formatMessage({ id: 'button.more' })}
                    img={obliczenia}
                    gif={true}
                    link={intl.formatMessage({ id: 'nav.produkty.obliczenia.slug' })}
                    imageRight={false}
                    background="04_yellow.png"
                  />
                  <FeatureSection
                    title={intl.formatMessage({ id: 'home.dane.title' })}
                    text={intl.formatMessage({ id: 'home.dane.desc' })}
                    buttonText={intl.formatMessage({ id: 'button.more' })}
                    img={potencjal}
                    gif={true}
                    link={intl.formatMessage({ id: 'nav.produkty.mapy.slug' })}
                    imageRight={true}
                    background="05_yellow.png"
                  />
                  <Footer />
                </ReactFullpage.Wrapper>
              </>
            );
          }}
        />
      </Layout>
    )
  }

}

IndexPage.propTypes = {
  intl: intlShape.isRequired
};

export default compose(
  withPageContext,
  injectIntl
)(IndexPage)
